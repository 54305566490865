import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  dow: {
    position: 'static',
    left: '0px',
    right: '0px',
    top: '3.625rem',
    bottom: '0px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: '140%',
    color: () =>
      theme.palette.customType === 'dark' ? '#BDBDBD' : colors.lightGrey,
    flex: 'none',
    order: 2,
    flexGrow: 0,
    //margin: '0.25rem 0rem',
  },
}));
