import React from 'react';
import { IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useStyles } from './styles';

interface IDrawerTitleRowProps {
  setOpen: () => void;
  isCheck?: boolean;
  isChecked?: boolean;
  titleChecked?: string;
  includeQuestionMark?: boolean;
  isSelect?: boolean;
  isSelected?: boolean;
  titleSelected?: string;
  messageSelected?: string;
  title?: string;
  includePadding?: boolean;
}

const DrawerTitleRowComp = ({
  setOpen,
  isCheck,
  isChecked,
  titleChecked,
  includeQuestionMark,
  isSelect,
  isSelected,
  titleSelected,
  messageSelected,
  title,
  includePadding,
}: IDrawerTitleRowProps) => {
  const classes = useStyles({ isSelect, isSelected, includePadding });
  const includeQM = !!includeQuestionMark ? includeQuestionMark : false;

  return (
    <div className={classes.container} onClick={() => setOpen()}>
      {isCheck ? (
        <div>
          {isChecked ? (
            <IconButton className={classes.selected}>
              <CheckCircleIcon />
            </IconButton>
          ) : (
            <React.Fragment>
              {includeQM ? (
                <IconButton className={classes.unselected}>
                  <HelpIcon />
                </IconButton>
              ) : null}
            </React.Fragment>
          )}
        </div>
      ) : null}

      {isCheck && (
        <div
          style={!includeQM && !isChecked ? { marginLeft: '3rem' } : {}}
          className={classes.titleChecked}
        >
          {titleChecked}
        </div>
      )}
      {isSelect && (
        <div className={classes.titleSelectedContainer}>
          <div className={classes.titleSelectedTitle}>{titleSelected}</div>
          <div className={classes.titleSelectedMessage}>{messageSelected}</div>
        </div>
      )}

      {!isCheck && !isSelect && <div className={classes.title}>{title}</div>}

      <div className={classes.arrowContainer}>
        {!!isCheck || (!!isSelect && !isSelected) || (!isCheck && !isSelect) ? (
          <IconButton className={classes.arrow}>
            <ArrowForwardIosIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

// @ts-ignore
DrawerTitleRowComp.whyDidYouRender = true;

export const DrawerTitleRow = DrawerTitleRowComp;
