import React from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface IStatusIndicatorProps {
  text: string;
  backgroundColor: string;
  className?: string;
  style?: React.CSSProperties;
}

export const StatusIndicator = ({
  text,
  backgroundColor,
  className,
  style,
}: IStatusIndicatorProps) => {
  const classes = useStyles({
    backgroundColor,
  });

  const classArr = classnames(classes.status, {
    [`${className}`]: !!className,
  });

  if (!!style) {
    return (
      <Typography
        component='div'
        variant='body1'
        className={classArr}
        style={style}
      >
        {text}
      </Typography>
    );
  } else {
    return (
      <Typography component='div' variant='body1' className={classArr}>
        {text}
      </Typography>
    );
  }
};
