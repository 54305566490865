import React from 'react';
import { useStyles } from './styles';

interface IAdCardSeatsFilledProps {
  playerCapacity: number;
  playerCount: number;
}

const AdCardSeatsFilledComp = ({
  playerCapacity,
  playerCount,
  ...props
}: IAdCardSeatsFilledProps) => {
  const classes = useStyles();

  return (
    <div className={classes.seatsFilledContainer}>
      <div className={classes.playerRatio}>
        <div className={classes.playerCount}>{playerCapacity-playerCount}</div>
        {/* <div className={classes.playerCapacity}>/{playerCapacity}</div> */}
      </div>
      <div className={classes.seatsFilled}>Open Seats</div>
    </div>
  );
};

// @ts-ignore
AdCardSeatsFilledComp.whyDidYouRender = {
  customName: 'AdCardSeatsFilled',
};

export const AdCardSeatsFilled = AdCardSeatsFilledComp;
