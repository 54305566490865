import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  paidGame: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  dow: {
    maxWidth: '85%',
    position: 'absolute',
    bottom: (props: IStyleProps) => (props.paidGame ? '3rem' : '1rem'),
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  dowHome: {
    width: 'calc(100% - 2rem)',
    position: 'absolute',
    bottom: (props: IStyleProps) => (props.paidGame ? '3rem' : '1rem'),
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '140%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  dowHomeInactive: {
    color: theme.palette.common.white,
    opacity: 0.3,
  },

  dowHomeActive: {
    color: theme.palette.common.white,
  },
}));
