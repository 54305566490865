import React from 'react';
import { useStyles } from './styles';
import { buildSummaryDow } from '../../../../utils/build-summary-dow';
import { useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';

interface IAdCardDaysOfWeek {
  daysOfWeek: Date[];
  isHomeScreen: boolean;
  paidGame: boolean;
  isQuickMatch?: boolean;
  quickMatchDate?: Date;
}

const AdCardDaysOfWeekComp = ({
  daysOfWeek,
  isHomeScreen,
  paidGame,
  isQuickMatch,
  quickMatchDate,
  ...props
}: IAdCardDaysOfWeek) => {
  const matchesWidth = useMediaQuery('(min-width:600px)');
  const classes = useStyles({ paidGame });

  var zone = !!quickMatchDate
    ? quickMatchDate
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2]
    : '';

  if (!!isQuickMatch && !!quickMatchDate) {
    return (
      <div className={classes.dow}>
        {`${format(quickMatchDate, 'EEE MMM d yyyy @ h:mm a')} ${zone}`}
      </div>
    );
  } else if (isHomeScreen && !matchesWidth) {
    const dowNumbers = daysOfWeek.map((d) => {
      return d.getDay();
    });

    return (
      <div className={classes.dowHome}>
        <div
          className={
            dowNumbers.includes(0)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          Su
        </div>
        <div
          className={
            dowNumbers.includes(1)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          M
        </div>
        <div
          className={
            dowNumbers.includes(2)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          Tu
        </div>
        <div
          className={
            dowNumbers.includes(3)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          W
        </div>
        <div
          className={
            dowNumbers.includes(4)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          Th
        </div>
        <div
          className={
            dowNumbers.includes(5)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          F
        </div>
        <div
          className={
            dowNumbers.includes(6)
              ? classes.dowHomeActive
              : classes.dowHomeInactive
          }
        >
          Sa
        </div>
      </div>
    );
  } else {
    return <div className={classes.dow}>{buildSummaryDow(daysOfWeek)}</div>;
  }
};

// @ts-ignore
AdCardDaysOfWeekComp.whyDidYouRender = {
  customName: 'AdCardDaysOfWeek',
};

export const AdCardDaysOfWeek = AdCardDaysOfWeekComp;
