import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  gameDetails: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    maxWidth: '70%',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '28px',
    color: theme.palette.common.white,
  },
  gameType: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.common.white,
  },
  adventurePlatform: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.common.white,
  },
  tagContainer: {
    marginTop: '.25rem',
  },
}));
