import React from 'react';
import { IconButton, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { AdTabOpenSeats } from '../AdTabOpenSeats';
import { AdTabCost } from '../AdTabCost';
import { AdTabGameDetails } from '../AdTabGameDetails';
import { ArrowForwardIcon } from '../../../Icons';

interface IAdTabProps {
  backgroundImage: string;
  title: string;
  gameType: string;
  adventurePlatform: string;
  isQuickMatch?: boolean;
  quickMatchDate?: Date;
  playerCapacity: number;
  playerCount: number;
  cost: number;
  daysOfWeek: Date[];
  onClick: (adId: string) => void;
  id: string;
}

const AdTabComp = React.memo(
  ({
    backgroundImage,
    title,
    gameType,
    adventurePlatform,
    isQuickMatch,
    quickMatchDate,
    playerCapacity,
    playerCount,
    cost,
    daysOfWeek,
    onClick,
    id,
    ...props
  }: IAdTabProps) => {
    const classes = useStyles({ backgroundImage });

    return (
      <Paper
        elevation={0}
        classes={{ root: classes.adTab }}
        onClick={() => {
          onClick(id);
        }}
      >
        <div className={classes.imageContainer}>
          <AdTabOpenSeats
            playerCount={playerCount}
            playerCapacity={playerCapacity}
          />
          {cost > 0 && <AdTabCost cost={cost} />}
        </div>
        <AdTabGameDetails
          title={title}
          gameType={gameType}
          adventurePlatform={adventurePlatform}
          daysOfWeek={daysOfWeek}
          isQuickMatch={isQuickMatch}
          quickMatchDate={quickMatchDate}
        />
        <IconButton className={classes.arrowContainer}>
          <ArrowForwardIcon viewBox='0 0 8 12' height='12' width='8' />
        </IconButton>
      </Paper>
    );
  }
);

// @ts-ignore
AdTabComp.whyDidYouRender = {
  customName: 'AdTab',
};

export const AdTab = AdTabComp;
