import React from 'react';
import { Paper } from '@material-ui/core';
import { useStyles } from './styles';

interface IFirstAdventureTokenProps {
  backgroundImage: string;
  adventureId: string;
  name: string;
  onTokenClick: (adventureId: string, isDefault: boolean) => void;
}

const FirstAdventureTokenComp = ({
  backgroundImage,
  adventureId,
  name,
  onTokenClick,
  ...props
}: IFirstAdventureTokenProps) => {
  const classes = useStyles({ backgroundImage });

  return (
    <div
      className={classes.firstAdventureTokenContainer}
      onClick={() => onTokenClick(adventureId, true)}
    >
      <Paper classes={{ root: classes.circleToken }}>
        <div className={classes.transparency} />
      </Paper>
      <div className={classes.bottomText}>{name}</div>
    </div>
  );
};

// @ts-ignore
FirstAdventureTokenComp.whyDidYouRender = {
  customName: 'FirstAdventureToken',
};

export const FirstAdventureToken = FirstAdventureTokenComp;
