import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  cost: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    textAlign: 'center',
    margin: '0 auto',
  },
  costContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '1.25rem',
    width: '5.5rem',
    top: '79.37%',
    bottom: '4.76%',
    left: '0.375rem',
    boxShadow: '0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0.25rem',
    backgroundColor: '#3276B5',
  },
}));
