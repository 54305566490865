import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  includePadding?: boolean;
  fullHeight?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  drawerMobile: {
    height: '100%',
    width: '100%',
    background: '#FDFDFD',
    overflow: 'hidden',
  },
  drawerDesktop: {
    borderTopLeftRadius: '0.25rem',
    minWidth: '25rem',
    maxWidth: '25rem',
    width: '25rem',
    height: (props: IStyleProps) =>
      !!props.fullHeight ? '100%' : 'calc(100% - 4rem)',
    top: (props: IStyleProps) => (!!props.fullHeight ? 0 : '4rem'),
    background: '#FDFDFD',
    overflow: 'hidden',
  },
  headerContainer: {
    height: '3rem',
    position: 'sticky',
    top: 'env(safe-area-inset-top, 0)',
    background: '#FDFDFD',
    zIndex: 1000,
    borderBottom: '1px solid #F3F3F3',
  },
  closeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeTitle: {
    textAlign: 'left',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#393636',
    paddingLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  closeIcon: {},
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  arrowIcon: {
    paddingLeft: '0.5rem',
  },
  arrowTitle: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#393636',
    paddingRight: '3rem',
  },
  contentContainer: {
    paddingLeft: (props: IStyleProps) =>
      !!props.includePadding ? '1rem' : '0rem',
    paddingRight: (props: IStyleProps) =>
      !!props.includePadding ? '1rem' : '0rem',
    paddingBottom: (props: IStyleProps) =>
      !!props.includePadding ? '4rem' : '0rem',
    marginTop: 'env(safe-area-inset-top, 0)',
    marginBottom: 'env(safe-area-inset-bottom, 0)',
    height:
      'calc(100% - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))',
  },
}));
