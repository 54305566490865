import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  backgroundImage: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  adventureTokenContainer: {
    minWidth: '9.5rem',
    maxWidth: '9.5rem',
    width: '9.5rem',
    display: 'inline',
    marginLeft: '.5rem',
    marginRight: '.5rem',

    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    position: 'relative',
  },
  circleToken: {
    borderRadius: '50%',
    height: '5.952rem',
    width: '5.952rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: (props: IStyleProps) => `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bottomText: {
    color: theme.palette.common.black,
    fontSize: '0.75rem',
    lineHeight: '100%',
    width: '100%',
    textAlign: 'center',
    //marginTop: '.50rem',
    marginTop: '.50rem',
  },
  lowerRightTagConnected: {
    position: 'absolute',
    bottom: '2rem',
    right: '1.6rem',
    cursor: 'pointer',
  },
  lowerRightTagRecruited: {
    position: 'absolute',
    bottom: '2rem',
    right: '1.25rem',
    cursor: 'pointer',
  },
  lowerRightTagInvited: {
    position: 'absolute',
    bottom: '2rem',
    right: '1.55rem',
    cursor: 'pointer',
  },
  lowerRightTagRallying: {
    position: 'absolute',
    bottom: '2rem',
    right: '1.55rem',
    cursor: 'pointer',
  },
  lowerRightTagActive: {
    position: 'absolute',
    bottom: '2rem',
    right: '1.55rem',
    cursor: 'pointer',
  },
  topLeftTagGm: {
    position: 'absolute',
    top: 0,
    left: '1.6rem',
    cursor: 'pointer',
  },
  topLeftTagLeader: {
    position: 'absolute',
    top: 0,
    left: '1.63rem',
    cursor: 'pointer',
  },
  notificationTagContainer: {
    position: 'absolute',
    top: '.15rem',
    right: '2rem',
    cursor: 'pointer',
  },
  notificationTagBadge: {
    backgroundColor: '#FC3078',
    border: '2px solid #FFFFFF',
    height: '1.75rem',
    minWidth: '1.75rem',
    borderRadius: '6.25rem',
    color: theme.palette.common.white,
  },
  tag: {
    borderColor: '#FFFFFF',
    borderWidth: '2px',
    borderRadius: '0.25rem',
  },
}));
