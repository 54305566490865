import React from 'react';
import {
  Button,
  Dialog,
  Drawer as MuiDrawer,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { useStyles } from './styles';

interface IAlertPromptProps {
  open: boolean;
  onClose: (proceed: boolean) => void;
  message: string;
}

const AlertPromptComp = ({ open, onClose, message }: IAlertPromptProps) => {
  const classes = useStyles();
  const matchesWidth = useMediaQuery('(min-width:600px)');

  return matchesWidth ? (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Typography className={classes.title}>{message}</Typography>
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={8}>
          <Button
            type='button'
            color='primary'
            onClick={() => onClose(true)}
            key='ok'
            className={classes.buttonDialog}
          >
            OK
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Dialog>
  ) : (
    <MuiDrawer
      anchor={matchesWidth ? 'right' : 'bottom'}
      open={open}
      onClose={() => onClose(false)}
      classes={
        matchesWidth
          ? {
              paper: classes.drawerDesktop,
            }
          : { paper: classes.drawerMobile }
      }
      transitionDuration={350}
    >
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Typography className={classes.title}>{message}</Typography>
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={8}>
          <Button
            type='button'
            color='primary'
            onClick={() => onClose(true)}
            key='ok'
            className={classes.button}
          >
            OK
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </MuiDrawer>
  );
};

// @ts-ignore
AlertPromptComp.whyDidYouRender = true;

export const AlertPrompt = AlertPromptComp;
