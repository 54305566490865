import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  drawerMobile: {
    height: '100vh',
    width: '100vw',
    background: '#FDFDFD',
    overflow: 'hidden',
  },
  drawerDesktop: {
    borderTopLeftRadius: '0.25rem',
    width: '25rem',
    height: 'calc(100vh - 4rem)',
    top: '4rem',
    background: '#FDFDFD',
    overflow: 'hidden',
    transition: 'transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
}));
