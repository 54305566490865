import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  newAdventureTokenContainer: {
    width: '7.9375rem',
    display: 'inline',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  circleToken: {
    borderRadius: '50%',
    height: '5.952rem',
    width: '5.952rem',
    border: '4px solid #3276B5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  addIcon: {
    height: '2.5rem',
    width: '2.5rem',
    color: '#3276B5',
  },
  bottomText: {
    color: '#265887',
    fontSize: '0.75rem',
    lineHeight: '100%',
    width: '100%',
    textAlign: 'center',
    marginTop: '.50rem',
  },
}));
