import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  gameDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    position: 'absolute',
    width: '8.4375rem',
    left: '7.25rem',
  },
  title: {
    position: 'static',
    width: '8.4375rem',
    left: '0px',
    top: '0px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    color: () => (theme.palette.customType === 'dark' ? '#56CCF2' : '#265887'),
    flex: 'none',
    order: 0,
    flexGrow: 0,
    //margin: '0.25rem 0rem',
  },
  gameType: {
    position: 'static',
    width: '8.4375rem',
    left: '0px',
    top: '40%',
    bottom: '40%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    color: () =>
      theme.palette.customType === 'dark' ? '#F2F2F2' : colors.lightBlack,
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '0.25rem 0rem',
  },
}));
