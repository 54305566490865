import React, { useMemo } from 'react';
import { Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { Add } from '@material-ui/icons';

export interface INewAdventureTokenProps {
  onTokenClick: () => void;
}

const NewAdventureTokenComp = React.memo(
  ({ onTokenClick, ...props }: INewAdventureTokenProps) => {
    const classes = useStyles();

    const addIconClasses = useMemo(() => {
      return { root: classes.addIcon };
    }, [classes.addIcon]);

    return (
      <div
        className={classes.newAdventureTokenContainer}
        onClick={() => onTokenClick()}
      >
        <Paper classes={{ root: classes.circleToken }}>
          <Add classes={addIconClasses} />
        </Paper>
        <div className={classes.bottomText}>New Portal</div>
      </div>
    );
  }
);

// @ts-ignore
NewAdventureTokenComp.whyDidYouRender = {
  customName: 'NewAdventureToken',
};

export const NewAdventureToken = NewAdventureTokenComp;
