import React from 'react';
import { Drawer as MuiDrawer, useMediaQuery } from '@material-ui/core';

import { useStyles } from './styles';

interface IAdDrawerBaseProps {
  open: boolean;
  onClose: () => void;
  children?: any;
}

const AdDrawerBaseComp = ({ open, onClose, children }: IAdDrawerBaseProps) => {
  const classes = useStyles();
  const matchesWidth = useMediaQuery('(min-width:600px)');

  return (
    <MuiDrawer
      anchor='right'
      open={open}
      onClose={() => onClose()}
      classes={
        matchesWidth
          ? {
              paper: classes.drawerDesktop,
            }
          : { paper: classes.drawerMobile }
      }
      transitionDuration={350}
    >
      {children}
    </MuiDrawer>
  );
};

// @ts-ignore
AdDrawerBaseComp.whyDidYouRender = {
  customName: 'AdDrawerBase',
};

export const AdDrawerBase = AdDrawerBaseComp;
