import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paidContainer: {
    height: '3rem',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#3276B5',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    alignItems: 'center',
  },
  paidTagContainer: {
    position: 'absolute',
    left: '1rem',
    marginBottom: '-0.375rem',
  },
  paidTag: {
    color: '#265887',
  },
  costContainer: {
    position: 'absolute',
    right: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  cost: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.3125rem',
    display: 'inline-block',
    paddingRight: '.3rem',
  },
  perSession: {
    color: '#A1C8ED',
    fontFamily: 'Roboto',
    fontSize: '.875rem',
    display: 'inline-block',
  },
}));
