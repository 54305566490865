import React from 'react';
import { Grid, Slider as MuiSlider, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { SliderProps } from '@material-ui/core/Slider';

interface ICustomSliderProps {
  id: string;
  label?: string;
  name: string;
  className?: any;
  onSliderChange: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
}

type TSliderProps = ICustomSliderProps & SliderProps;

const SliderComp = ({
  id,
  name,
  label,
  value,
  className,
  onSliderChange,
  ...props
}: TSliderProps) => {
  const classes = useStyles();

  const handleChange = (
    e: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    onSliderChange(e, newValue);
  };

  return (
    <Grid container justify='center' className={className ? className : ''}>
      <Grid item xs={12}>
        <Grid container direction='row'>
          <Grid item xs={6} className={classes.leftContainer}>
            <Typography variant='caption' className={classes.left}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.right}>
            <MuiSlider
              id={id}
              name={name}
              value={value}
              onChange={handleChange}
              style={{ padding: '0' }}
              aria-labelledby='continuous-slider'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// @ts-ignore
SliderComp.whyDidYouRender = true;

export const Slider = SliderComp;
