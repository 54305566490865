import React from 'react';
import { Button, Paper } from '@material-ui/core';
import { useStyles } from './styles';

interface ISuggestionCardProps {
  backgroundImage: string;
  cardText: string;
  buttonText: string;
  onButtonClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const SuggestionCardComp = React.memo(
  ({
    backgroundImage,
    cardText,
    buttonText,
    onButtonClick,
    ...props
  }: ISuggestionCardProps) => {
    const classes = useStyles({ backgroundImage });

    return (
      <Paper classes={{ root: classes.suggestionCard }}>
        <div className={classes.transparency} />

        <div className={classes.message}>{cardText}</div>

        <div className={classes.buttonContainer}>
          <Button classes={{ root: classes.button }} onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      </Paper>
    );
  }
);

// @ts-ignore
SuggestionCardComp.whyDidYouRender = {
  customName: 'SuggestionCard',
};

export const SuggestionCard = SuggestionCardComp;
