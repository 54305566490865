import React from 'react';
import { StatusIndicator } from '../../../StatusIndicator';
import { useStyles } from './styles';

interface IAdTabCostProps {
  cost: number;
}

const AdTabCostComp = ({ cost, ...props }: IAdTabCostProps) => {
  const classes = useStyles();

  return (
    <div className={classes.costContainer}>
      <StatusIndicator
        text={`$${Math.round(cost / 100)}/Session`}
        backgroundColor='#3276B5'
        className={classes.cost}
      />
    </div>
  );
};

// @ts-ignore
AdTabCostComp.whyDidYouRender = {
  customName: 'AdTabCost',
};

export const AdTabCost = AdTabCostComp;
