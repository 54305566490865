import { makeStyles, Theme } from '@material-ui/core';

import { colors } from '../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  decrement: {
    background: colors.deepBlue,
    color: colors.offWhite,
    height: '3rem',
    width: '3rem',
    borderTopLeftRadius: '0.25rem',
    borderBottomLeftRadius: '0.25rem',
  },
  decrementButton: {
    color: colors.offWhite,
    marginLeft: '0.25rem',
    '&:hover': {
      background: 'transparent',
      color: colors.offWhite,
    },
  },
  value: {
    background: '#F3F3F3',
    width: '4rem',
    height: '3rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueText: {
    color: colors.deepBlue,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '100%',
    width: '100%',
    textAlign: 'center',
  },
  increment: {
    background: colors.deepBlue,
    color: colors.offWhite,
    height: '3rem',
    width: '3rem',
    borderTopRightRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem',
  },
  incrementButton: {
    color: colors.offWhite,
    '&:hover': {
      background: 'transparent',
      color: colors.offWhite,
    },
  },
}));
