import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  backgroundImage: string;
  paidGame: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  adCard: {
    width: '100%',
    height: '100%',
    backgroundImage: (props: IStyleProps) => `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '1rem',
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  topTransparency: {
    position: 'absolute',
    height: '9.25rem',
    left: 0,
    right: 0,
    top: 0,
    background:
      'linear-gradient(0deg, rgba(57, 54, 54, 0.96) 0%, rgba(57, 54, 54, 0) 85.34%)',
    transform: 'rotate(-180deg)',
  },
  bottomTransparency: {
    position: 'absolute',
    height: '2.9375rem',
    left: 0,
    right: 0,
    bottom: (props: IStyleProps) => (props.paidGame ? '2.25rem' : 0),
    background:
      'linear-gradient(0deg, rgba(57, 54, 54, 0.96) 0%, rgba(57, 54, 54, 0) 85.34%)',
  },
  dow: {
    maxWidth: '75%',
    position: 'absolute',
    bottom: (props: IStyleProps) => (props.paidGame ? '3rem' : '1rem'),
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
}));
