import React from 'react';
import { StatusIndicator } from '../../../StatusIndicator';
import { useStyles } from './styles';

interface IAdCardMatchPercentageProps {
  matchPercentage?: number;
  paidGame: boolean;
}

const AdCardMatchPercentageComp = ({
  matchPercentage,
  paidGame,
  ...props
}: IAdCardMatchPercentageProps) => {
  const classes = useStyles({ paidGame });

  if (!!matchPercentage) {
    return (
      <div className={classes.matchTagContainer}>
        <StatusIndicator
          text={`${Math.round(matchPercentage)}% Match`}
          backgroundColor='rgba(0, 0, 0, 0.5)'
          className={classes.matchTag}
        />
      </div>
    );
  }
  return null;
};

// @ts-ignore
AdCardMatchPercentageComp.whyDidYouRender = {
  customName: 'AdCardMatchPercentage',
};

export const AdCardMatchPercentage = AdCardMatchPercentageComp;
