import React from 'react';
import { ButtonBase, Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface ImageCardProps {
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedImageId: string
  ) => void;
  id: string;
  name: string;
  url: string;
}

const ImageCardComp = ({ id, name, url, handleClick }: ImageCardProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      focusRipple
      key={id}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{ width: '100%' }}
      onClick={(e) => handleClick(e, id)}
    >
      <span
        className={classes.src}
        style={{ backgroundImage: `url(${url})` }}
      />
      <span className={classes.button}>
        <Typography
          component='span'
          variant='subtitle1'
          color='inherit'
          className={classes.title}
        >
          {name}
        </Typography>
      </span>
    </ButtonBase>
  );
};

// @ts-ignore
ImageCardComp.whyDidYouRender = true;

export const ImageCard = ImageCardComp;
