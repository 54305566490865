import React from 'react';
import {
  Button,
  Dialog,
  Drawer as MuiDrawer,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { useStyles } from './styles';

interface IConfirmPromptProps {
  open: boolean;
  onClose: (proceed: boolean) => void;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
}

const ConfirmPromptComp = React.memo(
  ({
    open,
    onClose,
    message,
    confirmLabel,
    cancelLabel,
  }: IConfirmPromptProps) => {
    const classes = useStyles();
    const matchesWidth = useMediaQuery('(min-width:600px)');

    const handleYes = () => {
      onClose(true);
    };

    const handleNo = () => {
      onClose(false);
    };

    const handleClose = () => {
      onClose(false);
    };

    const confirm = (
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Typography className={classes.title}>{message}</Typography>
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={8}>
          <Button
            type='button'
            color='primary'
            onClick={handleYes}
            key='confirm'
            className={classes.buttonDialog}
          >
            {confirmLabel}
          </Button>
          <Button
            type='button'
            color='primary'
            onClick={handleNo}
            key='cancel'
            className={classes.buttonDialog}
          >
            {cancelLabel}
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );

    return matchesWidth ? (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {confirm}
      </Dialog>
    ) : (
      <MuiDrawer
        anchor={'bottom'}
        open={open}
        onClose={() => onClose(false)}
        classes={{ paper: classes.drawerMobile }}
        transitionDuration={350}
      >
        {confirm}
      </MuiDrawer>
    );
  }
);

// @ts-ignore
ConfirmPromptComp.whyDidYouRender = {
  customName: 'ConfirmPrompt',
};

export const ConfirmPrompt = ConfirmPromptComp;
