import React from 'react';
import { IconButton } from '@material-ui/core';

import { useStyles } from './styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

interface ICounterProps {
  value: number;
  setValue: (value: number) => void;
  minValue: number;
  maxValue: number;
  isMoney: boolean;
}

type TCounterProps = ICounterProps;

const CounterComp = ({
  value,
  setValue,
  minValue,
  maxValue,
  isMoney,
}: TCounterProps) => {
  const classes = useStyles();

  const incrementValue = () => {
    if (value < maxValue) {
      setValue(value + 1);
    }
  };
  const decrementValue = () => {
    if (value > minValue) {
      setValue(value - 1);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div className={classes.decrement}>
        <IconButton
          className={classes.decrementButton}
          onClick={decrementValue}
        >
          <ArrowBackIos />
        </IconButton>
      </div>
      <div className={classes.value}>
        <div className={classes.valueText}>
          {isMoney ? '$' : ''}
          {value}
        </div>
      </div>
      <div className={classes.increment}>
        <IconButton
          className={classes.incrementButton}
          onClick={incrementValue}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
};

// @ts-ignore
CounterComp.whyDidYouRender = true;

export const Counter = CounterComp;
