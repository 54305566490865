import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: '0.75rem',
    color: theme.palette.warning.main,
  },
  leftContainer: {
    textAlign: 'center',
  },
  left: {
    color: '#000',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
  right: {
    paddingLeft: '1rem',
  },
}));
