import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  leader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    background: '#FC3078',
    color: '#FFFFFF',
    textAlign: 'center',
    border: '1px solid #FC3078',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    padding: '0.25rem',
    width: '3rem',
  },
  gm: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    background: '#7811FF',
    color: '#FFFFFF',
    textAlign: 'center',
    border: '1px solid #7811FF',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    padding: '0.25rem',
    width: '2rem',
  },
  player: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    background: '#33A6D7',
    color: '#FFFFFF',
    textAlign: 'center',
    border: '1px solid #33A6D7',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    padding: '0.25rem',
    width: '3rem',
  },
}));
