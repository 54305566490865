import React from 'react';
import { useStyles } from './styles';
import { buildSummaryDow } from '../../../../utils/build-summary-dow';
import { format } from 'date-fns';

interface IAdTabDaysOfWeek {
  daysOfWeek: Date[];
  isQuickMatch?: boolean;
  quickMatchDate?: Date;
}

const AdTabDaysOfWeekComp = ({
  daysOfWeek,
  isQuickMatch,
  quickMatchDate,
  ...props
}: IAdTabDaysOfWeek) => {
  const classes = useStyles();

  var zone = !!quickMatchDate
    ? quickMatchDate
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2]
    : '';

  if (!!isQuickMatch && !!quickMatchDate) {
    return (
      <div className={classes.dow}>
        {`${format(quickMatchDate, 'EEE MMM d yyyy @ h:mm a')} ${zone}`}
      </div>
    );
  } else {
    return <div className={classes.dow}>{buildSummaryDow(daysOfWeek)}</div>;
  }
};

// @ts-ignore
AdTabDaysOfWeekComp.whyDidYouRender = {
  customName: 'AdTabDaysOfWeek',
};

export const AdTabDaysOfWeek = AdTabDaysOfWeekComp;
