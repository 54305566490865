import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  backgroundColor: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  status: {
    backgroundColor: (props: IStyleProps) => props.backgroundColor,
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 700,
    padding: '0.1563rem',
    borderRadius: '0.125rem',
    borderWidth: 1,
    borderStyle: 'solid',
    textAlign: 'center',
    marginBottom: '0.375rem',
    display: 'inline-block',
    borderColor: (props: IStyleProps) => props.backgroundColor,
    minHeight: 0,
    minWidth: 0,
  },
}));
