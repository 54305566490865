import React from 'react';
import { StatusIndicator } from '../../../StatusIndicator';
import { useStyles } from './styles';

interface IAdCardPaidFooterProps {
  cost: number;
}

const AdCardPaidFooterComp = ({ cost, ...props }: IAdCardPaidFooterProps) => {
  const classes = useStyles();

  return (
    <div className={classes.paidContainer}>
      <div className={classes.paidTagContainer}>
        <StatusIndicator
          text='Paid Session'
          backgroundColor='#A1C8ED'
          className={classes.paidTag}
        />
      </div>
      <div className={classes.costContainer}>
        <div className={classes.cost}>{`$${Math.round(cost / 100)}`}</div>
        <div className={classes.perSession}>per session</div>
      </div>
    </div>
  );
};

// @ts-ignore
AdCardPaidFooterComp.whyDidYouRender = {
  customName: 'AdCardPaidFooter',
};

export const AdCardPaidFooter = AdCardPaidFooterComp;
