import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../styles/rootTheme';

interface IStyleProps {
  isSelect?: boolean;
  isSelected?: boolean;
  includePadding?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
    borderBottom: '1px solid #F3F3F3',
    background: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? colors.deepBlue : 'transparent',
    boxShadow: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected
        ? 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)'
        : '',
    color: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '#FDFDFD' : '',
    paddingLeft: (props: IStyleProps) =>
      !!props.includePadding ? '1rem' : '0rem',
    paddingRight: (props: IStyleProps) =>
      !!props.includePadding ? '1rem' : '0rem',
  },
  titleChecked: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    color: colors.lightBlack,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '80%',
    textAlign: 'left',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    color: colors.lightBlack,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '90%',
    textAlign: 'left',
  },
  selected: {
    color: colors.brightGreen,
  },
  unselected: {
    color: colors.lightGrey,
  },
  arrowContainer: {
    margin: 'auto',
  },
  arrow: {
    color: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '#FDFDFD' : colors.deepBlue,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  titleSelectedContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleSelectedTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '85%' : '100%',
    textAlign: 'left',
    color: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '#FDFDFD' : colors.lightBlack,
  },
  titleSelectedMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    marginTop: '0.25rem',
    marginBottom: 'auto',
    width: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '85%' : '100%',
    textAlign: 'left',
    color: (props: IStyleProps) =>
      !!props.isSelect && !!props.isSelected ? '#FDFDFD' : colors.slate,
  },
}));
