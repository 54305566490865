import React from 'react';

import { useStyles } from './styles';

interface ITagProps {
  roleName: string;
}

const TagComp = ({ roleName }: ITagProps) => {
  const classes = useStyles();

  if (roleName === 'LEADER') {
    return (
      <div>
        <div className={classes.leader}>Leader</div>
      </div>
    );
  } else if (roleName === 'GAME_MASTER') {
    return (
      <div>
        <div className={classes.gm}>GM</div>
      </div>
    );
  } else if (roleName === 'ADVENTURER') {
    return (
      <div>
        <div className={classes.player}>Player</div>
      </div>
    );
  }

  return null;
};

// @ts-ignore
TagComp.whyDidYouRender = true;

export const Tag = TagComp;
