import React from 'react';
import { StatusIndicator } from '../../..';
import { useStyles } from './styles';

interface IAdTabOpenSeatsProps {
  playerCapacity: number;
  playerCount: number;
}

const AdTabOpenSeatsComp = ({
  playerCapacity,
  playerCount,
  ...props
}: IAdTabOpenSeatsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.openSeatsContainer}>
      <StatusIndicator
        text={`${playerCapacity - playerCount}  Open Seat${
          playerCapacity - playerCount > 1 || playerCapacity - playerCount === 0
            ? 's'
            : ''
        }`}
        backgroundColor='#FC3078'
        className={classes.openSeats}
      />
    </div>
  );
};

// @ts-ignore
AdTabOpenSeatsComp.whyDidYouRender = {
  customName: 'AdTabOpenSeats',
};

export const AdTabOpenSeats = AdTabOpenSeatsComp;
