import React from 'react';
import { Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { AdCardSeatsFilled } from '../AdCardSeatsFilled';
import { AdCardPaidFooter } from '../AdCardPaidFooter';
import { AdCardGameDetails } from '../AdCardGameDetails';
import { AdCardMatchPercentage } from '../AdCardMatchPercentage';
import { AdCardDaysOfWeek } from '../AdCardDaysOfWeek';

interface IAdCardProps {
  backgroundImage: string;
  title: string;
  gameType: string;
  adventurePlatform: string;
  isQuickMatch?: boolean;
  quickMatchDate?: Date;
  matchPercentage?: number;
  playerCapacity: number;
  playerCount: number;
  cost: number;
  daysOfWeek: Date[];
  onClick: (adId: string) => void;
  id: string;
  isHomeScreen?: boolean;
}

const AdCardComp = React.memo(
  ({
    backgroundImage,
    title,
    gameType,
    adventurePlatform,
    isQuickMatch,
    quickMatchDate,
    matchPercentage,
    playerCapacity,
    playerCount,
    cost,
    daysOfWeek,
    onClick,
    id,
    isHomeScreen,
    ...props
  }: IAdCardProps) => {
    const classes = useStyles({ backgroundImage, paidGame: cost > 0 });

    return (
      <Paper
        classes={{ root: classes.adCard }}
        onClick={() => {
          onClick(id);
        }}
      >
        <div className={classes.topTransparency} />
        <div className={classes.bottomTransparency} />
        <AdCardGameDetails
          title={title}
          gameType={gameType}
          adventurePlatform={adventurePlatform}
          isQuickMatch={isQuickMatch}
          quickMatchDate={quickMatchDate}
        />
        <AdCardSeatsFilled
          playerCount={playerCount}
          playerCapacity={playerCapacity}
        />
        <AdCardMatchPercentage
          matchPercentage={matchPercentage}
          paidGame={cost > 0}
        />
        <AdCardDaysOfWeek
          daysOfWeek={daysOfWeek}
          isHomeScreen={!!isHomeScreen}
          isQuickMatch={isQuickMatch}
          quickMatchDate={quickMatchDate}
          paidGame={cost > 0}
        />
        {cost > 0 && <AdCardPaidFooter cost={cost} />}
      </Paper>
    );
  }
);

// @ts-ignore
AdCardComp.whyDidYouRender = {
  customName: 'AdCard',
};

export const AdCard = AdCardComp;
