import React from 'react';

interface ArrowForwardIconProps {
  height: string;
  width: string;
  viewBox: string;
}

export const ArrowForwardIcon = ({
  height,
  width,
  viewBox,
}: ArrowForwardIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.71824 5.99259L0.363075 10.5329L1.89212 12.0002L7.63711 5.99271L1.89212 0.000152588L0.363075 1.46739L4.71824 5.99259Z'
      fill='#3276B5'
    />
  </svg>
);
