import { format } from 'date-fns';

export const buildSummaryDow = (daysRaw: Date[]): string => {
  const days: string[] = [];
  if (daysRaw.length === 1) {
    days.push(format(new Date(daysRaw[0]), 'EEEE'));
  } else {
    for (const day of daysRaw) {
      days.push(format(new Date(day), 'EEE'));
    }
  }

  return days.join(', ');
};
