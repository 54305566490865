import React from 'react';
import {
  ClickAwayListener,
  Drawer as MuiDrawer,
  useMediaQuery,
} from '@material-ui/core';

import { useStyles } from './styles';

interface IAdventureDrawerBaseProps {
  open: boolean;
  onClose: () => void;
  children?: any;
  onClickAway?: (event: React.MouseEvent<Document, MouseEvent>) => void;
}

const AdventureDrawerBaseComp = ({
  open,
  onClose,
  children,
  onClickAway,
}: IAdventureDrawerBaseProps) => {
  const classes = useStyles();
  const matchesWidth = useMediaQuery('(min-width:600px)');

  return (
    <ClickAwayListener
      onClickAway={
        !!onClickAway
          ? onClickAway
          : (event: React.MouseEvent<Document, MouseEvent>) => {
              event.stopPropagation();
              event.preventDefault();
            }
      }
    >
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={() => {
          console.log('Running drawer onClose');
          onClose();
        }}
        BackdropProps={{ classes: { root: classes.backdrop } }}
        classes={
          matchesWidth
            ? {
                root: classes.drawerDesktopRoot,
                paper: classes.drawerDesktop,
              }
            : { paper: classes.drawerMobile }
        }
        transitionDuration={350}
      >
        {children}
      </MuiDrawer>
    </ClickAwayListener>
  );
};

// @ts-ignore
AdventureDrawerBaseComp.whyDidYouRender = {
  customName: 'AdventureDrawerBase',
};

export const AdventureDrawerBase = AdventureDrawerBaseComp;
