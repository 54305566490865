import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  backgroundImage: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  suggestionCard: {
    width: '100%',
    height: '15.75rem',
    backgroundImage: (props: IStyleProps) => `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '1rem',
    position: 'relative',
  },
  transparency: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 4,
    background:
      'linear-gradient(0deg, rgba(38, 88, 135, 0.6), rgba(38, 88, 135, 0.6))',
  },
  message: {
    position: 'absolute',
    width: 'calc(100% - 2rem)',
    top: '6.5625rem',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.3125rem',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    width: 'calc(100% - 2rem)',
    bottom: '1.75rem',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    background: 'transparent',
    border: '1px solid #FFF',
    width: 'auto',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  buttonLabel: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    color: theme.palette.common.white,
  },
}));
