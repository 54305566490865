import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  seatsFilledContainer: {
    width: '25%',
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  },
  playerRatio: {
    width: '100%',
    textAlign: 'right',
  },
  playerCount: {
    color: '#A1C8ED',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '24px',
    display: 'inline-block',
  },
  playerCapacity: {
    color: '#A1C8ED',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    display: 'inline-block',
  },
  seatsFilled: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '100%',
    textAlign: 'right',
    marginTop: -5,
  },
}));
