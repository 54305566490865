import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  openSeatsContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '1.25rem',
    width: '5.5rem',
    top: '4.76%',
    bottom: '79.37%',
    left: '0.375rem',
    background: '#FC3078',
    boxShadow: '0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0.25rem',
  },
  openSeats: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    textAlign: 'center',
    margin: '0px auto',
  },
}));
