import React from 'react';
import { AdTabDaysOfWeek } from '..';
import { useStyles } from './styles';

interface IAdTabGameDetailsProps {
  title: string;
  gameType: string;
  adventurePlatform: string;
  daysOfWeek: Date[];
  quickMatchDate?: Date;
  isQuickMatch?: boolean;
}

const AdTabGameDetailsComp = ({
  title,
  gameType,
  adventurePlatform,
  daysOfWeek,
  quickMatchDate,
  isQuickMatch,
  ...props
}: IAdTabGameDetailsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.gameDetails}>
      <div className={classes.title}>{title}</div>
      <div className={classes.gameType}>{gameType}</div>
      <AdTabDaysOfWeek
        daysOfWeek={daysOfWeek}
        isQuickMatch={isQuickMatch}
        quickMatchDate={quickMatchDate}
      />
    </div>
  );
};

// @ts-ignore
AdTabGameDetailsComp.whyDidYouRender = {
  customName: 'AdTabGameDetails',
};

export const AdTabGameDetails = AdTabGameDetailsComp;
