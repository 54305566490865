import React, { useEffect, useState } from 'react';
import { Badge, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { StatusIndicator } from '../../StatusIndicator';

interface IAdventureTokenProps {
  backgroundImage: string;
  title: string;
  notificationCount: number;
  isConnected?: boolean;
  isRecruited?: boolean;
  isInvited?: boolean;
  isRallying?: boolean;
  isActive?: boolean;
  isLeader?: boolean;
  isGm?: boolean;
  adventureId: string;
  onTokenClick: (adventureId: string, isDefaul: boolean) => void;
}

const AdventureTokenComp = ({
  backgroundImage,
  title,
  notificationCount,
  isConnected,
  isRecruited,
  isInvited,
  isRallying,
  isActive,
  isLeader,
  isGm,
  adventureId,
  onTokenClick,
  ...props
}: IAdventureTokenProps) => {
  const classes = useStyles({ backgroundImage });
  const [lowerRightTag, setLowerRightTag] = useState<JSX.Element | undefined>();
  const [topLeftTag, setTopLeftTag] = useState<JSX.Element | undefined>();
  const [notificationTag, setNotificationTag] = useState<
    JSX.Element | undefined
  >();

  useEffect(() => {
    let isMounted = true;
    let cssClass = '';
    let tagText = '';
    let backgroundColor = '';
    let createTag = false;

    if (!!isGm) {
      cssClass = classes.topLeftTagGm;
      tagText = 'GM';
      backgroundColor = '#7811FF';
      createTag = true;
    } else if (!!isLeader) {
      cssClass = classes.topLeftTagLeader;
      tagText = 'Leader';
      backgroundColor = '#FC3078';
      createTag = true;
    }

    if (createTag && isMounted) {
      setTopLeftTag(
        <div className={cssClass}>
          <StatusIndicator
            text={tagText}
            backgroundColor={backgroundColor}
            className={classes.tag}
          />
        </div>
      );
    }
    return () => {
      isMounted = false;
    };
  }, [
    classes.tag,
    classes.topLeftTagGm,
    classes.topLeftTagLeader,
    isGm,
    isLeader,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (notificationCount > 0) {
      if (isMounted) {
        setNotificationTag(
          <div className={classes.notificationTagContainer}>
            <Badge
              badgeContent={notificationCount}
              classes={{ badge: classes.notificationTagBadge }}
            />
          </div>
        );
      }
    } else {
      if (isMounted) {
        setNotificationTag(undefined);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    classes.notificationTagBadge,
    classes.notificationTagContainer,
    notificationCount,
  ]);

  useEffect(() => {
    let isMounted = true;
    let cssClass = '';
    let tagText = '';
    let backgroundColor = '';
    let createTag = false;
    let color = '#FFFFFF';

    if (!!isActive) {
      cssClass = classes.lowerRightTagActive;
      tagText = 'Active';
      backgroundColor = '#00D42F';
      createTag = true;
    } else if (!!isRallying) {
      cssClass = classes.lowerRightTagRallying;
      tagText = 'Rallying';
      backgroundColor = '#6BD876';
      createTag = true;
    } else if (!!isInvited) {
      cssClass = classes.lowerRightTagInvited;
      tagText = 'Invited';
      backgroundColor = '#A1C8ED';
      color = '#265887';
      createTag = true;
    } else if (!!isRecruited) {
      cssClass = classes.lowerRightTagRecruited;
      tagText = 'Recruited';
      backgroundColor = '#7811FF';
      createTag = true;
    } else if (!!isConnected) {
      cssClass = classes.lowerRightTagConnected;
      tagText = 'Connected';
      backgroundColor = '#7811FF';
      createTag = true;
    } else {
      cssClass = '';
      tagText = '';
      backgroundColor = '';
      createTag = false;
    }

    if (isMounted) {
      if (createTag) {
        setLowerRightTag(
          <div className={cssClass}>
            <StatusIndicator
              text={tagText}
              backgroundColor={backgroundColor}
              className={classes.tag}
              style={{ color }}
            />
          </div>
        );
      } else {
        setLowerRightTag(undefined);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    classes.lowerRightTagConnected,
    classes.lowerRightTagRecruited,
    classes.lowerRightTagInvited,
    classes.lowerRightTagRallying,
    classes.lowerRightTagActive,
    isActive,
    isConnected,
    isInvited,
    isRallying,
    isRecruited,
    classes.tag,
  ]);

  return (
    <div
      className={classes.adventureTokenContainer}
      onClick={() => onTokenClick(adventureId, false)}
    >
      <Paper classes={{ root: classes.circleToken }}></Paper>
      <div className={classes.bottomText}>{title}</div>

      {!!notificationTag && notificationTag}
      {!!topLeftTag && topLeftTag}
      {!!lowerRightTag && lowerRightTag}
    </div>
  );
};

// @ts-ignore
AdventureTokenComp.whyDidYouRender = {
  customName: 'AdventureToken',
};

export const AdventureToken = AdventureTokenComp;
