import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  backgroundImage: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  firstAdventureTokenContainer: {
    minWidth: '9.5rem',
    maxWidth: '9.5rem',
    width: '9.5rem',
    display: 'inline',
    marginLeft: '.5rem',
    marginRight: '.5rem',

    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  circleToken: {
    borderRadius: '50%',
    height: '5.952rem',
    width: '5.952rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: (props: IStyleProps) => `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    margin: '0 auto',
    cursor: 'pointer',
  },
  transparency: {
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background:
      'linear-gradient(0deg, rgba(38, 88, 135, 0.6), rgba(38, 88, 135, 0.6))',
  },
  bottomText: {
    color: theme.palette.common.black,
    fontSize: '0.75rem',
    lineHeight: '100%',
    width: '100%',
    textAlign: 'center',
    marginTop: '.50rem',
  },
}));
