import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  drawerMobile: {
    width: '100%',
    background: '#FDFDFD',
    overflow: 'hidden',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
  },
  drawerDesktop: {
    borderTopLeftRadius: '0.25rem',
    width: '25rem',
    height: 'calc(100% - 4rem)',
    top: '4rem',
    background: '#FDFDFD',
    overflow: 'hidden',
  },
  title: {
    textAlign: 'center',
    marginTop: '1.25rem',
    padding: '1rem',
  },
  button: {
    marginBottom: '4rem',
    '&:hover': {
      backgroundColor: colors.lightBlue,
      color: colors.white,
    },
  },
  buttonDialog: {
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: colors.lightBlue,
      color: colors.white,
    },
  },
}));
