import React from 'react';
import {
  Drawer as MuiDrawer,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowBackIos } from '@material-ui/icons';

import { useStyles } from './styles';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { colors } from '../../../styles/rootTheme';

interface IDrawerBaseProps {
  open: boolean;
  onClose: (success?: boolean) => void;
  showClose: boolean;
  title: string;
  includePadding?: boolean;
  children?: any;
  fullHeight?: boolean;
  disableBackdrop?: boolean;
}

const DrawerBaseComp = ({
  open,
  onClose,
  showClose,
  title,
  includePadding,
  children,
  fullHeight,
  disableBackdrop
}: IDrawerBaseProps) => {
  const classes = useStyles({ includePadding, fullHeight });
  const matchesWidth = useMediaQuery('(min-width:600px)');

  return (
    <MuiDrawer
      anchor={matchesWidth ? 'right' : 'bottom'}
      open={open}
      onClose={() => onClose()}
      classes={
        matchesWidth
          ? {
              paper: classes.drawerDesktop,
            }
          : { paper: classes.drawerMobile }
      }
      transitionDuration={350}
      BackdropProps={{ invisible: disableBackdrop }}
    >
      <div className={classes.headerContainer}>
        {showClose ? (
          <div className={classes.closeContainer}>
            <div className={classes.closeTitle}>{title}</div>
            <div className={classes.closeIcon}>
              <IconButton
                onClick={() => onClose()}
                style={{ color: colors.deepBlue }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={classes.arrowContainer}>
            <div className={classes.arrowIcon}>
              <IconButton
                onClick={() => onClose()}
                style={{ color: colors.deepBlue }}
              >
                <ArrowBackIos />
              </IconButton>
            </div>
            <div className={classes.arrowTitle}>{title}</div>
          </div>
        )}
      </div>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        autoHide={false}
        className={classes.contentContainer}
      >
        {children}
      </SimpleBar>
    </MuiDrawer>
  );
};

// @ts-ignore
DrawerBaseComp.whyDidYouRender = true;

export const DrawerBase = DrawerBaseComp;
