import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../styles/rootTheme';

interface IStyleProps {
  backgroundImage: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  adTab: {
    display: 'flex',
    width: '100%',
    height: '7.875rem',
    position: 'relative',
    borderRadius: '0.25rem',
    border: '1px solid #BDBDBD',
    boxShadow: '0rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.1)',
    background: () =>
      theme.palette.customType === 'dark' ? '#4F4F4F' : colors.offWhite,
    cursor: 'pointer',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'relative',
    width: '6.25rem',
    height: '7.875rem',
    left: '-1px',
    backgroundImage: (props: IStyleProps) => `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '0.25rem 0rem 0rem 0.25rem',
  },
  arrowContainer: {
    color: () =>
      theme.palette.customType === 'dark' ? '#56CCF2' : colors.deepBlue,
    position: 'absolute',
    right: '0.085rem',
    alignItems: 'center',
    padding: '.625rem',
  },
}));
