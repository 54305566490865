import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { StatusIndicator } from '../../../StatusIndicator';
import { useStyles } from './styles';

interface IAdCardGameDetailsProps {
  title: string;
  gameType: string;
  adventurePlatform: string;
  isQuickMatch?: boolean;
  quickMatchDate?: Date;
}

const AdCardGameDetailsComp = ({
  title,
  gameType,
  adventurePlatform,
  isQuickMatch,
  quickMatchDate,
  ...props
}: IAdCardGameDetailsProps) => {
  const classes = useStyles();
  const [qmTagText, setQmTagText] = useState('');

  useEffect(() => {
    let isMounted = true;

    if (!!isQuickMatch && !!quickMatchDate) {
      const today = new Date();
      const tomorrow = addDays(new Date(), 1);
      const twoDays = addDays(new Date(), 2);
      const threeDays = addDays(new Date(), 3);

      if (
        quickMatchDate.getFullYear() === today.getFullYear() &&
        quickMatchDate.getMonth() === today.getMonth() &&
        quickMatchDate.getDate() === today.getDate()
      ) {
        if (isMounted) {
          setQmTagText('Playing Today');
        }
      } else if (
        quickMatchDate.getFullYear() === tomorrow.getFullYear() &&
        quickMatchDate.getMonth() === tomorrow.getMonth() &&
        quickMatchDate.getDate() === tomorrow.getDate()
      ) {
        if (isMounted) {
          setQmTagText('Playing Tomorrow');
        }
      } else if (
        quickMatchDate.getFullYear() === twoDays.getFullYear() &&
        quickMatchDate.getMonth() === twoDays.getMonth() &&
        quickMatchDate.getDate() === twoDays.getDate()
      ) {
        if (isMounted) {
          setQmTagText('Playing in 2 days');
        }
      } else if (
        quickMatchDate.getFullYear() === threeDays.getFullYear() &&
        quickMatchDate.getMonth() === threeDays.getMonth() &&
        quickMatchDate.getDate() === threeDays.getDate()
      ) {
        if (isMounted) {
          setQmTagText('Playing in 3 days');
        }
      } else {
        if (isMounted) {
          setQmTagText('Scheduled Game');
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [isQuickMatch, quickMatchDate]);

  return (
    <div className={classes.gameDetails}>
      <div className={classes.title}>{title}</div>
      <div className={classes.gameType}>{gameType}</div>
      <div className={classes.adventurePlatform}>{adventurePlatform}</div>

      {!!isQuickMatch && !!quickMatchDate && (
        <div className={classes.tagContainer}>
          <StatusIndicator text={qmTagText} backgroundColor='#33A6D7' />
        </div>
      )}
    </div>
  );
};

// @ts-ignore
AdCardGameDetailsComp.whyDidYouRender = {
  customName: 'AdCardGameDetails',
};

export const AdCardGameDetails = AdCardGameDetailsComp;
