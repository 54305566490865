import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  paidGame: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  matchTagContainer: {
    position: 'absolute',
    bottom: (props: IStyleProps) => (props.paidGame ? '4.5rem' : '2.5rem'),
    maxWidth: '80%',
  },
  matchTag: {
    borderColor: '#FFFFFF',
  },
}));
