import React, { useEffect, useState } from 'react';
import { ButtonBase, Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import type { IAttachment } from '../../types';
import { DrawerBase } from '../Drawers';
import { ImageCard } from '../ImageCard';

interface ImagePickerProps {
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedImageId: string
  ) => void;
  id?: string | undefined;
  name?: string | undefined;
  url?: string | undefined;
  images: IAttachment[];
}

const ImagePickerComp = ({ id, images, handleClick }: ImagePickerProps) => {
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [openPicker, setOpenPicker] = useState(false);
  const handlePickerOpenClick = () => {
    setOpenPicker(true);
  };
  const handlePickerCloseClick = () => {
    setOpenPicker(false);
  };

  const handlePickerSelectClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string
  ) => {
    for (const image of images) {
      if (image.id === value) {
        setSelectedId(image.id);
        setSelectedName(image.name);
        setSelectedUrl(image.file);
      }
    }
    handlePickerCloseClick();
    handleClick(event, value);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!!id) {
        // Use the currently selected background
        setSelectedId(id);
        setSelectedName('Select New Image');
        for (const image of images) {
          if (image.id === id) {
            setSelectedUrl(image.file);
          }
        }
        //setSelectedUrl(url);
      } else {
        // Go lookup the default
        for (const image of images) {
          if (image.name === `We've got this...`) {
            setSelectedId(image.id);
            setSelectedName('Select New Image');
            setSelectedUrl(image.file);
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [id, images]);

  return (
    <React.Fragment>
      <ButtonBase
        focusRipple
        key={selectedId}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{ width: '100%' }}
        onClick={handlePickerOpenClick}
      >
        <span
          className={classes.src}
          style={{ backgroundImage: `url(${selectedUrl})` }}
        />
        <span className={classes.button}>
          <Typography
            component='span'
            variant='subtitle1'
            color='inherit'
            className={classes.title}
          >
            {selectedName}
          </Typography>
        </span>
      </ButtonBase>
      <DrawerBase
        open={openPicker}
        onClose={handlePickerCloseClick}
        showClose={false}
        title={'Cover Image'}
      >
        <Grid container justify='center' style={{ marginBottom: '4rem' }}>
          {images
            .slice()
            .sort((a: IAttachment, b: IAttachment) =>
              a.name.localeCompare(b.name)
            )
            .map((image, index) => {
              return (
                <Grid item xs={12} key={index} style={{ margin: '1rem' }}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                      <ImageCard
                        id={image.id}
                        name={image.name}
                        url={image.file}
                        handleClick={handlePickerSelectClick}
                      />
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </DrawerBase>
    </React.Fragment>
  );
};

// @ts-ignore
ImagePickerComp.whyDidYouRender = true;

export const ImagePicker = ImagePickerComp;
