import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';

import type { IApolloUser } from '../../types';
import { Grid } from '@material-ui/core';

interface IPlaystyleData {
  combat: number;
  puzzlesLogic: number;
  socialIntrigue: number;
  playerStory: number;
  rolePlaying: number;
  strictRules: number;
  [key: string]: number;
}

interface IPlaystyleChartProps {
  gameData?: IPlaystyleData;
  userData?: IPlaystyleData;
  comparisonUser?: IApolloUser | null;
  comparisonUserLabel?: string | null;
  marginTop?: number;
  marginBottom?: number;
  onClick?: any;
}

const dataKeys = [
  'combat',
  'puzzlesLogic',
  'socialIntrigue',
  'playerStory',
  'rolePlaying',
  'strictRules',
];

const dataNames = [
  'Combat',
  'Puzzles',
  'Social',
  'Player Driven',
  'Roleplaying',
  'Homebrew',
];

const buildUserData = (user: IApolloUser | null | undefined) => {
  if (user) {
    return {
      combat: user.combat,
      puzzlesLogic: user.puzzlesLogic,
      socialIntrigue: user.socialIntrigue,
      playerStory: user.playerStory,
      rolePlaying: user.rolePlaying,
      strictRules: user.strictRules,
    };
  }

  return undefined;
};

const dataForRadarBoth = (
  gameData?: IPlaystyleData,
  userData?: IPlaystyleData,
  userLabel?: string | null
) => {
  const label = !!userLabel ? userLabel : 'You';

  return dataKeys.map((el, index) => ({
    name: dataNames[index],
    Adventure: gameData ? gameData[el] : null,
    [label]: userData ? userData[el] : null,
  }));
};

const dataForRadarGame = (gameData?: IPlaystyleData) => {
  return dataKeys.map((el, index) => ({
    name: dataNames[index],
    Adventure: gameData ? gameData[el] : null,
  }));
};

const dataForRadarUser = (
  userData?: IPlaystyleData,
  userLabel?: string | null
) => {
  const label = !!userLabel ? userLabel : 'You';

  return dataKeys.map((el, index) => ({
    name: dataNames[index],
    [label]: userData ? userData[el] : null,
  }));
};

const buildKeys = (
  gameData?: IPlaystyleData,
  userData?: IPlaystyleData,
  userLabel?: string | null
) => {
  const label = !!userLabel ? userLabel : 'You';

  let keys = [];
  if (gameData) {
    keys.push('Adventure');
  }
  if (userData) {
    keys.push(label);
  }
  return keys;
};

const PlaystyleChartComp = ({
  gameData,
  userData,
  comparisonUser,
  comparisonUserLabel,
  marginTop,
  marginBottom,
  onClick,
}: IPlaystyleChartProps) => {
  const keys = buildKeys(
    gameData,
    !!userData ? userData : buildUserData(comparisonUser),
    comparisonUserLabel
  );

  const chartData =
    gameData && comparisonUser
      ? dataForRadarBoth(
          gameData,
          buildUserData(comparisonUser),
          comparisonUserLabel
        )
      : gameData
      ? dataForRadarGame(gameData)
      : dataForRadarUser(
          !!userData ? userData : buildUserData(comparisonUser),
          comparisonUserLabel
        );

  const chartColors =
    gameData && comparisonUser
      ? ['#3276B5', '#FFD700']
      : gameData
      ? ['#3276B5']
      : ['#FFD700'];

  const BuildCharts = () => {
    // isInteractive={false}
    return (
      <ResponsiveRadar
        data={chartData}
        keys={keys}
        indexBy='name'
        maxValue={100}
        margin={{
          top: marginTop ? marginTop : 20,
          bottom: marginBottom ? marginBottom : 20,
        }}
        colors={chartColors}
        fillOpacity={0.5}
        legends={[]}
        tooltipFormat={(_value: string) => ''}
      />
    );
  };

  return (
    <React.Fragment>
      {onClick ? (
        <Grid container direction='row'>
          <Grid item xs={12} style={{ height: '11rem' }}>
            <BuildCharts />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='row'>
          <Grid item xs={12} style={{ height: '11rem' }}>
            <BuildCharts />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

// @ts-ignore
PlaystyleChartComp.whyDidYouRender = true;

export const PlaystyleChart = PlaystyleChartComp;
